/* eslint-disable no-console */
import '../sass/index.scss';
// Lozad
import lozad from './util/lozad';
import {
	$body, lazyModule, $window, $document
} from './util/helpers';
import Polyfill from './polyfills';
import SiteCookie from './cookies';
import ChangeSvg from './components/change-svg';
import 'intersection-observer';

window.isAppLoaded = window.isAppLoaded || false;

/**
 * App component
 * @class App
 */
class App {
	/**
	 * Initialise app
	 * @function init
	 */
	init() {
		// initialise polyfills
		Polyfill();

		// Load modules
		lazyModule();

		// lazy loads elements with default selector as '.lozad'
		const self = this;
		const observer = lozad('.lozad', {
			loaded(el) {
				// Custom implementation on a loaded element
				const animateAttr = 'data-animate-class';
				const parents = $(el).parents(`[${animateAttr}]`);
				const animateClass = parents.attr(animateAttr);
				if (animateClass !== undefined) {
					parents.addClass(animateClass);
					parents.trigger('initAnimation');
				}
			}
		});
		observer.observe();

		const preLoadImages = document.querySelectorAll(
			'.preload-images .lozad'
		);
		// trigger the load of a image before it appears on the viewport
		[...preLoadImages].map(item => observer.triggerLoad(item));


		// section load
		const sections = document.querySelectorAll('.rs-section');

		const options = {
			rootMargin: '0px',
			threshold: 0.25
		};

		const callback = (entries, viewportObserver) => {
			entries.forEach((entry) => {
					const $target = entry.target;
					// console.log(entry, $target);
					if (entry.intersectionRatio >= 0.25) {
						$target.classList.add('is-visible');
					} else {
						$target.classList.remove('is-visible');
					}
			});
		};

		const viewportObserver = new IntersectionObserver(callback, options);

		sections.forEach((section, index) => {
			viewportObserver.observe(section);
		});

		$body.removeClass('body-hide');
		$document.on('ready', () => {
			$window.scrollTop(0);
			window.scrollTo(0, 0);
		});
		$window.on('unload', () => {
			$window.scrollTop(0);
		});

		// init Site cookies js
		this.SiteCookie = new SiteCookie();
		this.SiteCookie.init();

		// init svg
		this.svg = new ChangeSvg();
		this.svg.init();

		$('.js-copyto-clipboard').on('click', this.copyToClipboard.bind(this));
	}

	copyToClipboard(e) {
		const $temp = $('<input>');
		const $target = $(e.target).is('a') ? $(e.target) : $(e.target).parents('a');
		const copyText = $target.data('copyText') || window.location.href;

		$target.removeClass('active');

		$body.append($temp);
		$temp.val(copyText).select();
		document.execCommand('copy');
		$temp.remove();

		$target.addClass('active');
	}
}

/**
 * initialise app
 * @type function
 */
// eslint-disable-next-line func-names
export default (function () {
	// load CSS & thems
	if (!window.isAppLoaded) {
		// initialise app
		const rs = new App();
		rs.init();


		window.isAppLoaded = true;
	}
}());
